<template>
  <form @submit.prevent="onsubmit">
    <va-input
      v-model="userId"
      :label="$t('auth.useridOrEmail')"
      :error="!!useridErrors.length"
      :error-messages="useridErrors"
      autofocus
    />

    <va-input
      v-model="password"
      type="password"
      :label="$t('auth.password')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />

    <div class="auth-layout__options d-flex align--center justify--space-between">
      <!--
        <va-checkbox v-model="keepLoggedIn" class="mb-0" :label="$t('auth.keep_logged_in')"/>
      -->
      <router-link class="ml-1 link" :to="{name: 'recover-password'}">{{$t('auth.recover_password')}}</router-link>
    </div>

    <div v-if="isChecking" class="d-flex justify--center mt-3">
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#000000"
      />
    </div>
    <div v-else class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">{{ $t('auth.login') }}</va-button>
    </div>
  </form>
</template>

<script>
import { LoopingRhombusesSpinner } from 'epic-spinners'

export default {
  name: 'login',
  components: {
    LoopingRhombusesSpinner,
  },
  data () {
    return {
      userId: '',
      password: '',
      keepLoggedIn: false,
      useridErrors: [],
      passwordErrors: [],
      isChecking: false,
      timeoutID: '',
    }
  },
  computed: {
    formReady () {
      return !this.useridErrors.length && !this.passwordErrors.length
    },
  },
  methods: {
    onsubmit () {
      this.useridErrors = this.userId ? [] : [this.$t('auth.errUserIdEmailRequired')]
      this.passwordErrors = this.password ? [] : [this.$t('auth.errPasswordRequired')]
      if (!this.formReady) {
        return
      }

      this.isChecking = true

      this.timeoutID = setTimeout(this.loginTimeout, 5000)

      this.$userauth.login({ userid: this.userId, password: this.password })
        .then(response => {
          this.$userforms.getUserForms()
            .then(() => {
              clearTimeout(this.timeoutID)
              this.isChecking = false
              this.$router.push('/')
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(err => {
          console.log(err)
          clearTimeout(this.timeoutID)
          this.useridErrors = [this.$t('auth.errLoginFailed')]
          this.passwordErrors = [this.$t('auth.errLoginFailed')]
          this.isChecking = false
        })
    },
    loginTimeout () {
      this.isChecking = false
      this.useridErrors = [this.$t('auth.errTimeout')]
    },
  },
}
</script>

<style lang="scss">
</style>
